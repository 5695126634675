import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ['query', 'filtered']
  static values = { query: { type: String, default: '' } }

  filter() {
    this.filteredTargets.forEach((element) => {
      this.filterElement(element)
    })
  }
  filterElement(element) {
    const hideElement = !element.dataset.text.toLowerCase().includes(this.query)
    element.hidden = hideElement
  }
  queryTargetConnected(target) {
    target.value = this.queryValue
  }
  queryTargetDisconnected(target) {
    this.queryValue = target.value
  }
  filteredTargetConnected(target) {
    this.filterElement(target)
  }

  get query() {
    return this.queryTarget.value.toLowerCase();
  }
}