
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  close(evt) {
    this.element.classList.add("is-moving-offscreen-left")
  }

  closeAndSubmit(evt) {
    evt.preventDefault()
    this.element.classList.add("is-updating")
    this.element.requestSubmit()
    this.element.classList.remove("is-updating")
  }
}
