import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "panel"]
  static values = { visible: String }

  onChange(event) {
    this.showOrHidePanel()
  }

  showOrHidePanel() {
    if (this.hasPanelTarget) {
      if (this.hasFieldTarget) { 
        const value = this.fieldTarget.value
        if (value == this.visibleValue) {
          this.panelTarget.classList.remove("d-none")
        } else {
          this.panelTarget.classList.add("d-none")
        }
      }
    }
  }

  panelTargetConnected(panel) {
    this.showOrHidePanel()
  }

  fieldTargetConnected(field) {
    this.changeHandler = this.onChange.bind(this)
    field.addEventListener("change", this.changeHandler)
    this.showOrHidePanel()
  }

}

